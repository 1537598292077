function viewMoreText (e) {
  const btn = $(e);
  const parent = btn.closest('.text-collapsed');

  if (!btn.hasClass('toggled')){
    $('.text', parent).addClass('show-text');
    btn.html('View less').addClass('toggled');
  } else {
    $('.text', parent).removeClass('show-text')
    btn.html('View more').removeClass('toggled');
    $(window).scrollTop(parent.offset().top);

  }
}

function loadMore(el, total, step = 4) {

    const btn = $(el);
    const remaining = btn.closest('.loadContainer').find('.hidden');

    if(remaining.length >0){

      let startPos = remaining.eq(0).index();

      if(startPos <= total) {

        for(let i = 0; i< step; i++){

          let event = $('#event-' + startPos);
          event.show();
          event.removeClass('hidden');
          startPos++;
          if(startPos == total){
            btn.hide();
          }

        }
      }else {
        btn.hide();
      }

    }else{
      btn.hide();
    }


}

$(function() {


  const MAX_CHAR = 400;

  function cropContent(element, MAX_CHAR){

    const txt = element.text();

    element.html(
      `<span>${txt.slice(0, MAX_CHAR)}</span>
       <span>${txt.slice(MAX_CHAR ,txt.length)}</span>`
    );

  };

  function setBackgroundBox(){

      const dateBoxes = $('.dateBoxSection');

      dateBoxes.each((index, element) => {

          const current = $(element);
          if (!current.next('.boxBody').length ) {
              current.children().addClass("dateBoxes-wrapp");
          }
      });
  };

  setBackgroundBox();

  function cropText(MAX_CHAR, element){

    let children = element.find('.text').children();

    let overIndex = -1;
    let totalCharacters = 0;
    let remainingChar = 0;

    children.each(function(index){

      let txt = $(this).text();

      totalCharacters = totalCharacters + txt.length;

      if(totalCharacters > MAX_CHAR){
        overIndex = index;
        remainingChar = txt.length - (totalCharacters - MAX_CHAR);
        cropContent($(this), remainingChar);
        return false;
      }

    });

    if(overIndex == -1) return;

    children.each(function(index){
      if(index > overIndex){
        $(this).addClass('d-none');
      }
    });
  }

  $(".text-collapsed").each(function(){
    cropText(MAX_CHAR, $(this));
  });

  baguetteBox.run('.gallery');
});


// carousel

function groupBy(number){

  $('.carousel-showmanymoveone .carousel-item').each(function() {


    let itemToClone = $(this);
    // console.log("current", $(this)[0]);


      for (let i=1;i<number;i++) {

        itemToClone = itemToClone.next();

        // console.log("next", itemToClone[0]);
        // // wrap around if at end of item collection

        if (!itemToClone.length) {
          $(this).remove();
          // itemToClone = $(this).siblings(':first');
          // itemToClone.find('.carouselBox').clone().appendTo($(this).find('.cloneContainer'));
        }else{
          // grab item, clone, add marker class, add to collection
          // itemToClone.children(':first-child').clone()
          itemToClone.find('.carouselBox').clone()
            .appendTo($(this).find('.cloneContainer'));
        }

      }

  });
}

function cropSlides(width){
  if(width > 576){
    if(width < 992){
      groupBy(2);
    }else{
      groupBy(4);
    }
  }
}

(function(){

  let width = $( window ).width();
  cropSlides(width);


  let carousel = $('#calendarCarousel');
  carousel.on('slide.bs.carousel', function (event) {
    let date = carousel.find(`.carousel-item.${event.to}`).data('date');
    $('.calendar-title').html(date);
  });

  // $( window ).resize(function() {
  //   cropSlides($( window ).width());
  // });

}());

