const errorMsgs = {
  "email": "Please enter a valid email",
  "zipCode": "Please enter a valid ZIP Code",
  "phone": "Please enter a valid phone number",
  "required": "This field is required please enter a value"
}

function filledRequiredFields(requiredInputs){

  let requiredPass = true;

  requiredInputs.each(function(){
    if(!minLength($(this).val(),2)){
      requiredPass = false;
    }
  });

  return requiredPass;

}

function filledRequiredFields(requiredInputs){

  let requiredPass = true;

  requiredInputs.each(function(){
    if(!minLength($(this).val(),2)){
      requiredPass = false;
    }
  });

  return requiredPass;

}

function handleInput(el, cond, errorMsg){
  if (cond) {
    el.removeClass("is-invalid");
  } else {
    el.addClass("is-invalid");
    el.siblings(".invalid-feedback").html(errorMsg);
  }
}

function validateEmail (email) {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function validateZipCode(zipCode){
  let zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/;
  return zipCodePattern.test(zipCode);
}

function minLength(val, minChar){
  return val.length >= minChar;
}

function renderErrorMsgsInForm(form, errors){

  for(var name in errors){

    let input = form.find('input[name='+name+']');
    input.addClass('is-invalid');
    input.sibling('.invalid-feedback').html(errors[name]);
  }

}
