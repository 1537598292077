function validateForm(tag){

  let el = $(tag);
  let type = el.attr("type");
  let id = el.attr("id");
  let val = el.val();
  let form =  el.closest("#registerForm");

  if (type == "email") {
    handleInput(el, validateEmail(val), errorMsgs.email);
  } else if(el.hasClass('zipcode')){
    handleInput(el, validateZipCode(val), errorMsgs.zipCode);
  } else if(el.prop("required") && type == "text"){
    handleInput(el, minLength(val, 2), errorMsgs.required);
  }

}

function urlParams(params){

  let counter = 0;
  let json =  JSON.parse(params);
  let urlResponse = "?";

  Object.keys(json).forEach((key) => {

    if(counter> 0){
        urlResponse+="&&";
    }

    urlResponse += `${key}=${json[key]}`;
    counter ++;

  });

  return encodeURI(urlResponse);

}

function loadmodal (modalSelector, url, params) {

  const modal = $(modalSelector);
  const urlparams = params
  // const urlparams = urlParams(params);

  modal.find('.modal-content').load(url +urlparams, function(){ //not working with profile
    modal.modal('show');
  });

  // $.get(url +urlparams, function( data ) {
  //   modal.find('.modal-content').html(data);
  //   modal.modal('show');
  // });

};


function checkTicketForm(form){
  let requiredFields = form.find("input:required[type='text']");
  let validEmail = validateEmail(form.find("input:required[type='email']").val());
  let validZip = validateZipCode(form.find("input.zipcode").val());
  return validEmail && validZip && filledRequiredFields(requiredFields);
}


$("body").on("submit", "#ticketForm", function(e){

  e.preventDefault();


  let form = $("#ticketForm");
  let data = form.serialize();
  let ticketModal = $("#ticket-modal");
  let submitBtn = $("#submitTicket");


  if(checkTicketForm(form)) {

    submitBtn.prop("disabled", true);

    $.ajax({
      type: 'POST',
      url: form.attr('action'),
      data: data,
      success: function (data) {

        ticketModal.modal('hide');
        submitBtn.prop("disabled", false);
        $('#feedback-modal').html(data);

      },
      error: function (xhr, type) {
        ticketModal.modal('hide');
        submitBtn.prop("disabled", false);
        $('#feedback-error').html('Ajax error!');

      }
    });
  }

});

